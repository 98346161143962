import { useTranslate } from 'react-admin';
import { useController } from 'react-hook-form';
import { Grid, TextField, Typography } from '@mui/material';

const numberInputProps = {
  type: 'number',
  inputProps: { min: 0 },
};

const percentageInputProps = {
  type: 'number',
  inputProps: { min: 0, max: 100 },
};

const props = {
  price: numberInputProps,
  days: numberInputProps,
  percentage: percentageInputProps,
  applicationDate: { type: 'date' },
};

const Field = ({ id, name, control }) => {
  const translate = useTranslate();
  const {
    field: { value, onChange },
  } = useController({ name: id, control });

  const handleChange = ({ target }) => {
    onChange({ ...value, [name]: target.value });
  };

  return (
    value && (
      <Grid key={id} item container xs={3}>
        <Grid item>
          <TextField
            {...props[name]}
            value={value[name]}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item alignSelf="center">
          <Typography variant="body1">
            {translate(`resources.parameters.units.${name}`)}
          </Typography>
        </Grid>
      </Grid>
    )
  );
};

export default Field;
