import {
  Datagrid,
  DeleteButton,
  List,
  TextField,
  TextInput,
} from 'react-admin';
import {Typography} from "@mui/material";
import {TrashIcon} from "../../icons/CustomIcons";

const teacherCharacteristicsFilters = [<TextInput source="q" label="Search" alwaysOn />];

const SectorList = () => {
  return (
    <List filters={teacherCharacteristicsFilters}>
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <TextField label="resources.sectors.fields.vat" source="vat.label" />
        <TextField source="commentary" />
        <Typography align='right'>
          <DeleteButton icon={<TrashIcon/>}/>
        </Typography>
      </Datagrid>
    </List>
  );
};

export default SectorList;
