import {
  AutocompleteArrayInput,
  Edit,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
  useTranslate
} from "react-admin";

const SectorTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const label = translate('resources.sectors.name', { smart_count: 1 });

  return `${label}: ${record.name}`;
};

const SectorEdit = () => (
  <Edit title={<SectorTitle />}>
    <SimpleForm>
      <TextInput source='name' validate={required()} />
      <ReferenceInput source="vat.id" reference="vats">
        <SelectInput
          label='resources.sectors.fields.vat' 
          optionText="label" 
          defaultValue='' />
      </ReferenceInput>
      <TextInput source='commentary' validate={required()} />
      <ReferenceArrayInput
        source="countryIds"
        reference="countries"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteArrayInput label='resources.sectors.fields.countries' optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
);

export default SectorEdit;
