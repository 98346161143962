import {
  Datagrid,
  List,
  TextField,
  DeleteButton,
} from 'react-admin';
import { Typography } from "@mui/material";
import { TrashIcon } from "../../icons/CustomIcons";

const TeacherCharacteristicList = ({ redirect = true }) => {
  return (
    <List
      resource='teacher_characteristics'
      storeKey="teacherCharacteristics"
      filters={null}
      exporter={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" />
        <Typography align='right'>
          <DeleteButton redirect={redirect} icon={<TrashIcon />} />
        </Typography>
      </Datagrid>
    </List>
  );
};

export default TeacherCharacteristicList;
