import { Datagrid, DeleteButton, List, TextField } from 'react-admin';
import { TrashIcon } from 'icons/CustomIcons';
import { Typography } from '@mui/material';

const LanguageLevelList = ({ redirect = true }) => {
  return (
    <>
      <List resource='language_levels' hasCreate exporter={false}>
        <Datagrid rowClick='edit'>
          <TextField source='level' />
          <TextField source='description' />
          <Typography align='right'>
            <DeleteButton redirect={redirect} icon={<TrashIcon />} />
          </Typography>
        </Datagrid>
      </List>
    </>
  );
};

export default LanguageLevelList;
