import { useContext } from 'react';
import { useTranslate } from 'react-admin';
import { useForm } from 'react-hook-form';
import {
  Button,
  Modal,
  FormControl,
  FormLabel,
  TextField,
  Stack,
} from '@mui/material';
import useCreateLanguage from '../../../hooks/useCreateLanguage';
import CenterBox from './CenteredBox';
import { ModalContext, modalKind } from '../modal';
import useDuplicateNotify from '../../../hooks/useDuplicateNotify';

const AddLanguage = ({ storeLanguage, removeLanguage }) => {
  const { modal, closeModal } = useContext(ModalContext);
  const translate = useTranslate();
  const create = useCreateLanguage();
  const { register, handleSubmit, reset } = useForm();

  const notifyDuplicated = useDuplicateNotify();

  const close = () => {
    reset();
    closeModal();
  };

  const onSubmit = ({ language: name, ...data }) => {
    const lang = { name, ...data };
    create(lang, {
      onUndo: (lang) => removeLanguage(lang),
      onError: (err) => {
        notifyDuplicated(err);
        removeLanguage(lang);
      },
    });
    storeLanguage(lang);
    close();
  };

  return (
    <Modal open={modal.kind === modalKind.addLanguage} onClose={close}>
      <CenterBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <Stack direction='row' justifyContent='space-between'>
              <FormControl sx={{ width: '70%' }}>
                <FormLabel>
                  {translate('resources.offered_languages.form.language')}
                </FormLabel>
                <TextField {...register('language')} required />
              </FormControl>
              <FormControl sx={{ width: '20%' }}>
                <FormLabel>
                  {translate('resources.offered_languages.form.code')}
                </FormLabel>
                <TextField {...register('code')} required />
              </FormControl>
            </Stack>
            <FormControl>
              <FormLabel>
                {translate('resources.offered_languages.form.shortDescription')}
              </FormLabel>
              <TextField {...register('shortDescription')} required />
            </FormControl>
            <FormControl>
              <FormLabel>
                {translate('resources.offered_languages.form.longDescription')}
              </FormLabel>
              <TextField {...register('longDescription')} multiline required />
            </FormControl>
          </Stack>
          <Stack direction='row' justifyContent='space-around'>
            <Button variant='primary' onClick={close}>
              {translate('ra.action.cancel')}
            </Button>
            <Button variant='primary' type='submit'>
              {translate('ra.action.add')}
            </Button>
          </Stack>
        </form>
      </CenterBox>
    </Modal>
  );
};

export default AddLanguage;
