import { useContext } from 'react';
import { useTranslate, useCreate, useNotify, useRefresh } from 'react-admin';
import { useForm } from 'react-hook-form';
import {
  Modal,
  Stack,
  FormControl,
  FormLabel,
  TextField,
  Button,
} from '@mui/material';
import CenteredBox from './CenteredBox';
import { ModalContext, modalKind } from '../modal';

const AddComplementary = ({ offeredLanguage }) => {
  const { modal, closeModal } = useContext(ModalContext);
  const translate = useTranslate();
  const { register, handleSubmit, reset } = useForm();
  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();

  const close = () => {
    closeModal();
    reset();
  };

  const onSubmit = ({ name, code, min, max }) => {
    const payload = {
      data: { language: offeredLanguage.id, name, code, min, max },
    };
    const options = {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('ra.notification.updated', {
          undoable: true,
          messageArgs: { smart_count: 1 },
        });

        reset();
        close();
        refresh();
      },
    };

    create('offered_language_complementaries', payload, options);
  };

  return (
    <Modal open={modal.kind === modalKind.addComplementary} onClose={close}>
      <CenteredBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <Stack direction="row" justifyContent="space-between">
              <FormControl sx={{ width: '70%' }}>
                <FormLabel>
                  {translate('resources.offered_languages.form.language')}
                </FormLabel>
                <TextField {...register('name')} required />
              </FormControl>
              <FormControl sx={{ width: '20%' }}>
                <FormLabel>
                  {translate('resources.offered_languages.form.code')}
                </FormLabel>
                <TextField {...register('code')} required />
              </FormControl>
            </Stack>
            <FormControl>
              <FormLabel>
                {translate('resources.offered_languages.form.min')}
              </FormLabel>
              <TextField {...register('min')} required />
            </FormControl>
            <FormControl>
              <FormLabel>
                {translate('resources.offered_languages.form.max')}
              </FormLabel>
              <TextField {...register('max')} required />
            </FormControl>
            <Stack direction="row" justifyContent="space-around">
              <Button variant="primary" onClick={close}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Añadir
              </Button>
            </Stack>
          </Stack>
        </form>
      </CenteredBox>
    </Modal>
  );
};

export default AddComplementary;
