/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Grid, Typography } from '@mui/material';
import useGetParameters from 'hooks/api/useGetParameters';
import useUpdateParameters from 'hooks/useUpdateParameters';
import { useEffect } from 'react';
import { Form, Title, undoableEventEmitter } from 'react-admin';
import { useForm } from 'react-hook-form';
import ParameterField from './form/Field';
import Toolbar from './form/Toolbar';

const ParametersForm = ({ resource }) => {
  const { data: parameters } = useGetParameters(resource);

  const getDefaults = () =>
    Object.fromEntries(parameters?.map(({ id, value }) => [id, value]) ?? []);

  const { control, reset, handleSubmit } = useForm({
    defaultValues: getDefaults(),
  });

  const updateParameters = useUpdateParameters(resource);
  const onSubmit = (data) => {
    const parameters = Object.entries(data).map(([key, value]) => ({
      id: key,
      value,
    }));

    updateParameters(parameters);
    undoableEventEmitter.once('end', ({ isUndo }) => {
      if (isUndo) reset(getDefaults());
    });
  };

  useEffect(() => {
    reset(getDefaults());
  }, [parameters]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Title title={`resources.${resource}.name`} />
      <Toolbar />
      <Card>
        {parameters?.map(({ id, key, value }) => (
          <Grid key={id} container>
            <Grid item xs={6} alignSelf='center'>
              <Typography variant='body1'>{key}</Typography>
            </Grid>
            {Object.keys(value).map((key) => (
              <ParameterField
                key={`${id}_${key}`}
                id={id}
                name={key}
                control={control}
              />
            ))}
          </Grid>
        ))}
      </Card>
    </Form>
  );
};

export default ParametersForm;
