import { Grid } from '@mui/material';
import LanguageDetails from './languageView/LanguageDetails';
import Complementaries from './languageView/Complementaries';
import LanguageLevelList from '../languageLevels/LanguageLevelList';
import TeacherCharacteristicList from '../teacherCharacteristic/TeacherCharacteristicList';
import LanguageExamsList from '../languageExams/LanguageExams';

const LanguageView = ({ language }) => {
  return (
    <Grid container spacing={4} sx={{ marginTop: '-12px'}}>
      <Grid item xs={5}>
        <LanguageDetails language={language} />
      </Grid>
      <Grid item xs={7}>
        <Complementaries language={language} />
      </Grid>
      <Grid item xs={6}>
        {language && <LanguageLevelList redirect={false} />}
      </Grid>
      <Grid item xs={6}>
        {language && <LanguageExamsList redirect={false} language={language} />}
      </Grid>
      <Grid item xs={6}>
        <TeacherCharacteristicList redirect={false} />
      </Grid>
    </Grid>
  );
};

export default LanguageView;
