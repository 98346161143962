/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from 'react';
import { Title, TopToolbar, useListContext, useTranslate } from 'react-admin';
import { Button, Box, Tabs, Tab } from '@mui/material';
import LanguageView from './LanguageView';
import { ModalContext } from './modal';

const Languages = ({
  activeLanguage: { language, index },
  setActiveLanguage,
  languages,
  setLanguages,
}) => {
  const { addLanguage } = useContext(ModalContext);
  const translate = useTranslate();

  const { data } = useListContext();

  const handleChange = (_, index) => {
    setActiveLanguage({ language: languages[index], index });
  };

  const updateLanguage = (languages) => {
    const newIndex = index >= data.length ? 0 : index;
    setActiveLanguage({ language: languages[newIndex], index: newIndex });
  };

  useEffect(() => {
    setLanguages(data);
    if (data) updateLanguage(data);
  }, [data]);

  return (
    <Box>
      <Title title="resources.offered_languages.name" />
      <TopToolbar>
        <Button variant="primary" onClick={addLanguage}>
          {translate('resources.offered_languages.buttons.addNewLanguage')}
        </Button>
      </TopToolbar>
      <Tabs
        value={index}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {languages?.map(({ name }, index) => (
          <Tab key={index} label={name} />
        ))}
      </Tabs>
      {language && <LanguageView language={language} />}
    </Box>
  );
};

export default Languages;
