import { useState } from 'react';
import { useNotify, useUpdate, useTranslate } from 'react-admin';
import { TextField, Stack, Typography, Button } from '@mui/material';

const View = ({ text, setEdit }) => {
  const translate = useTranslate();
  return (
    <>
      <Typography>{text}</Typography>
      <Button onClick={() => setEdit(true)} sx={{ width: '40px' }}>
        {translate('ra.action.edit')}
      </Button>
    </>
  );
};

const Edit = ({ language, setEdit }) => {
  const translate = useTranslate();
  const [text, setText] = useState(language.longDescription);

  const [update] = useUpdate();
  const notify = useNotify();

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = { id: language.id, data: { longDescription: text } };
    const options = {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('ra.notification.updated', {
          undoable: true,
          messageArgs: { smart_count: 1 },
        });

        setText(text);
        setEdit(false);
      },
    };

    update('offered_languages', payload, options);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        value={text}
        onChange={(e) => setText(e.target.value)}
        required
        multiline
        sx={{ width: '400px' }}
      />
      <Stack
        direction="row"
        justifyContent="space-around"
        sx={{ maxWidth: '400px' }}
      >
        <Button onClick={() => setEdit(false)}>
          {translate('ra.action.cancel')}
        </Button>
        <Button type="submit">{translate('ra.action.save')}</Button>
      </Stack>
    </form>
  );
};

const LongDescription = ({ language }) => {
  const [edit, setEdit] = useState(false);
  return (
    <Stack spacing={2}>
      {edit ? (
        <Edit language={language} setEdit={setEdit} />
      ) : (
        <View text={language.longDescription} setEdit={setEdit} />
      )}
    </Stack>
  );
};

export default LongDescription;
