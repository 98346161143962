import { useContext } from 'react';
import {
  Datagrid,
  TextField,
  NumberField,
  useTranslate,
  BooleanField,
  DeleteWithConfirmButton,
  EditButton,
  List,
  Toolbar,
} from 'react-admin';
import { Button } from '@mui/material';
import { ModalContext } from '../modal';

const Complementaries = ({ language }) => {
  const { addComplementary, editComplementary } = useContext(ModalContext);
  const translate = useTranslate();

  const handleEdit = (e) => {
    e.preventDefault();
    const hash = e.target.href.split('/').at(-1);
    editComplementary(hash);
  };

  return (
    <>
      <Toolbar sx={{ justifyContent: 'end' }}>
        <Button variant="primary" onClick={() => addComplementary(language.id)}>
          {translate('resources.offered_languages.buttons.addComplementary')}
        </Button>
      </Toolbar>
      <List
        resource="offered_language_complementaries"
        filters={null}
        filter={{ offered_language: language.id }}
        title=" "
        actions={null}
      >
        <Datagrid>
          <TextField
            label={translate('resources.complementaries.name')}
            source="name"
          />
          <NumberField
            label={translate('resources.complementaries.teacherCount')}
            source="teacherCount"
          />
          <NumberField
            label={translate('resources.complementaries.minTeachers')}
            source="minTeachers"
          />
          <NumberField
            label={translate('resources.complementaries.maxTeachers')}
            source="maxTeachers"
          />
          <BooleanField
            label={translate('resources.complementaries.locked')}
            source="locked"
          />
          <DeleteWithConfirmButton
            confirmTitle="resources.complementaries.confirmTitle"
            resource="offered_language_complementaries"
            redirect={false}
          />
          <EditButton onClick={handleEdit} />
        </Datagrid>
      </List>
    </>
  );
};

export default Complementaries;
