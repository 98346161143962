import { 
  AutocompleteArrayInput,
  Create,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const SectorCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source='name' validate={required()} />
      <ReferenceInput source="vat.id" reference="vats">
        <SelectInput
          label='resources.sectors.fields.vat' 
          optionText="label" 
          defaultValue=''
          validate={required()} />
      </ReferenceInput>
      <TextInput source='commentary' validate={required()} />
      <ReferenceArrayInput
        source="countryIds"
        reference="countries"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteArrayInput label='resources.sectors.fields.countries' optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default SectorCreate;
