import { createContext } from 'react';

export const ModalContext = createContext();

export const modalKind = {
  none: 'NONE',
  addLanguage: 'ADD_LANGUAGE',
  addComplementary: 'ADD_COMPLEMENTARY',
  editComplementary: 'EDIT_COMPLEMENTARY',
};
