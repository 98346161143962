/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { useDataProvider, useUpdate, useNotify } from 'react-admin';
import { useForm } from 'react-hook-form';
import {
  Modal,
  Stack,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Checkbox,
  Button,
} from '@mui/material';
import CenteredBox from './CenteredBox';
import { ModalContext, modalKind } from '../modal';

const EditComplementary = () => {
  const { modal, closeModal } = useContext(ModalContext);
  const dataProvider = useDataProvider();
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    const { language } = modal;

    if (!language) return;

    dataProvider.getComplementary(language).then(({ data }) => {
      const { locked, minTeachers, maxTeachers } = data;
      reset({ locked, min: minTeachers, max: maxTeachers });
      setLanguage(data);
    });
  }, [modal.language]);

  const { register, handleSubmit, reset } = useForm();
  const [update] = useUpdate();
  const notify = useNotify();
  const onSubmit = (data) => {
    const payload = { id: language.id, data };
    const options = {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('ra.notification.updated', {
          undoable: true,
          messageArgs: { smart_count: 1 },
        });
        closeModal();
      },
    };

    update('offered_language_complementaries', payload, options);
  };

  return (
    <Modal
      open={modal.kind === modalKind.editComplementary}
      onClose={closeModal}
    >
      <CenteredBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <FormControl>
              <FormLabel>Min</FormLabel>
              <TextField {...register('min')} required />
            </FormControl>
            <FormControl>
              <FormLabel>Max</FormLabel>
              <TextField {...register('max')} required />
            </FormControl>
            <FormControlLabel
              control={<Checkbox name="locked" {...register('locked')} />}
              label="Locked"
            />
            <Stack direction="row" justifyContent="space-around">
              <Button variant="primary" onClick={closeModal}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Editar
              </Button>
            </Stack>
          </Stack>
        </form>
      </CenteredBox>
    </Modal>
  );
};

export default EditComplementary;
