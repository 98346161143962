import { useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  useStore,
} from "react-admin";
import { TrashIcon } from "../../icons/CustomIcons";
import { Typography } from '@mui/material';

const LanguageExamsList = ({ language, redirect = true }) => {
  const [offeredLanguageHash, setOfferedLanguageHash] = useStore('language_exams.offered_language_hash');

  useEffect(() => {
    if (offeredLanguageHash !== language?.id) {
      setOfferedLanguageHash(language?.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  return (
    <List resource="language_exams"
      filter={{ offered_language: language.id }}
      exporter={false}
    >
    <Datagrid rowClick='edit'>
      <TextField source='name' />
      <TextField source='description' />
      <TextField source='institution' />
      <TextField source='abbreviation' />
      <TextField source='tag' />
      <TextField source='landing' />
      <Typography align='right'>
        <DeleteButton redirect={redirect} icon={<TrashIcon />} />
      </Typography>
    </Datagrid>
  </List>
)}

export default LanguageExamsList;