/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useReducer } from 'react';
import { ListBase, ListToolbar } from 'react-admin';
import AddLanguage from './modals/AddLanguage';
import AddComplementary from './modals/AddComplementary';
import EditComplementary from './modals/EditComplementary';
import Languages from './Languages';
import filters from './filters';
import { ModalContext, modalKind } from './modal';

const initialState = {
  language: null,
  kind: modalKind.none,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'open_modal':
      return { kind: action.modal, language: action.language };
    case 'close_modal':
      return { kind: modalKind.none, language: null };
    default:
      return state;
  }
};

const OfferedLanguagesManagement = () => {
  const [modal, dispatch] = useReducer(reducer, initialState);
  const [languages, setLanguages] = useState(null);
  const [activeLanguage, setActiveLanguage] = useState({
    language: null,
    index: 0,
  });

  const value = {
    modal,

    addLanguage: () =>
      dispatch({
        type: 'open_modal',
        modal: modalKind.addLanguage,
        language: null,
      }),

    addComplementary: () =>
      dispatch({
        type: 'open_modal',
        modal: modalKind.addComplementary,
        language: null,
      }),

    editComplementary: (language) =>
      dispatch({
        type: 'open_modal',
        modal: modalKind.editComplementary,
        language,
      }),

    closeModal: () => dispatch({ type: 'close_modal' }),
  };

  const storeLanguage = (lang) => setLanguages((prev) => [...prev, lang]);
  const removeLanguage = (lang) =>
    setLanguages((prev) => prev.filter(({ name }) => name !== lang.name));

  return (
    <ModalContext.Provider value={value}>
      <ListBase>
        <ListToolbar filters={filters} />
        <AddLanguage
          storeLanguage={storeLanguage}
          removeLanguage={removeLanguage}
        />
        <AddComplementary offeredLanguage={activeLanguage.language} />
        <EditComplementary />
        <Languages
          activeLanguage={activeLanguage}
          setActiveLanguage={setActiveLanguage}
          languages={languages}
          setLanguages={setLanguages}
        />
      </ListBase>
    </ModalContext.Provider>
  );
};

export default OfferedLanguagesManagement;
