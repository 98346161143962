import { List, Datagrid, TextField, DeleteButton } from 'react-admin';

const AccountingAccountList = () => (
  <List>
    <Datagrid>
      <TextField source="number" />
      <TextField source="name" />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default AccountingAccountList;
